#root {
  height: 100vh;
  width: 100%;
  /* position: absolute;
    bottom: 0px; */
  /* background-color: #fff; */
  background-position: 0px 0px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  overflow: scroll;
  /* border-top-left-radius: 4em;
      border-top-right-radius: 4em; */
  /* Fallback */
  background: url("https://firebasestorage.googleapis.com/v0/b/actitud-landing.appspot.com/o/GDG_ACTITUD_LANDING_3.png?alt=media&token=944e7342-7ecb-4677-86e4-37a4bb6b7309");
}

@media (max-width: 450px) {
  #root {
    height: 100vh;
    width: 100%;
    /* position: absolute;
      bottom: 0px; */
    /* background-color: #fff; */
    /* background-position: -838px 0px; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    overflow: scroll;
    /* border-top-left-radius: 4em;
        border-top-right-radius: 4em; */
    /* Fallback */
    background: url("https://firebasestorage.googleapis.com/v0/b/actitud-landing.appspot.com/o/GDG_ACTITUD_LANDING_3.png?alt=media&token=944e7342-7ecb-4677-86e4-37a4bb6b7309");
  }
}

.imagenhasg {
  width: 100%;
}

.pildoras {
  width: 100% !important;
  height: 300px !important;
}

.mainvideo {
  position: relative;
  width: auto;
  height: 350px;
}
