.imagen {
  /* height: 350px; */
  width: 100%;
  /* position: absolute;
  bottom: 0px; */
  background-color: #b1b8bd;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* border-top-left-radius: 4em;
    border-top-right-radius: 4em; */
  /* Fallback */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/actitud-landing.appspot.com/o/GDG_ACTITUD_LANDING_3.png?alt=media&token=944e7342-7ecb-4677-86e4-37a4bb6b7309");
}

.landing {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.row {
  padding: 0px;
  margin: 0px;
}

@media (max-width: 768px) {
  .imagen {
    height: 350px;
    width: 100%;
    background-position: -350px 0px;
    background-repeat: no-repeat;
    background-size: 326% auto;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/actitud-landing.appspot.com/o/GDG_ACTITUD_LANDING_3.png?alt=media&token=944e7342-7ecb-4677-86e4-37a4bb6b7309);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 44%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
